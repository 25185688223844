import { getAwarenessQuery, getFAQsQuery, getHomeQuery, getProgramQuery } from '@/api/queries';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useContent = () => {
  const { i18n } = useTranslation();
  const { data: home } = useQuery(getHomeQuery());
  const { data: awareness } = useQuery(getAwarenessQuery());
  const { data: program } = useQuery(getProgramQuery());
  const { data: faqs } = useQuery(getFAQsQuery());

  return {
    home: home?.[i18n.language as 'de' | 'en'] ?? '',
    awareness: awareness?.[i18n.language as 'de' | 'en'] ?? '',
    program: program?.[i18n.language as 'de' | 'en'] ?? '',
    faqs: faqs?.[i18n.language as 'de' | 'en'] ?? [],
  };
};
