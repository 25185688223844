import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from '@/locales/de/translation.json';
import en from '@/locales/en/translation.json';

export const supportedLanguages = ['en', 'de'] as const;
export type SupportedLanguage = (typeof supportedLanguages)[number];

export const supportedLanguageMap: Record<SupportedLanguage, string> = {
  de: 'Deutsch',
  en: 'English',
};

interface I18InitOptions extends InitOptions {
  supportedLngs: SupportedLanguage[];
}

const initOptions: I18InitOptions = {
  debug: false,
  fallbackLng: 'de',
  defaultNS: 'translation',
  lng: 'de',
  supportedLngs: [...supportedLanguages],
  interpolation: {
    prefix: '{',
    suffix: '}',
  },
  resources: {
    en: { translation: en },
    de: { translation: de },
  },
};

// use(LanguageDetector) to see if user browser has a preferred language that we support
i18n.use(initReactI18next).init(initOptions);

if (import.meta.hot) {
  import.meta.hot.on('locales-update', () => {
    i18n.reloadResources().then(() => {
      i18n.changeLanguage(i18n.language);
    });
  });
}

export default i18n;
