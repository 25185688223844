import { useContent } from '@/hooks/useContent';
import { HtmlEditor } from '@packages/ui';
import { useEffect } from 'react';

function createSlug(str: string) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

export const FaqPage = () => {
  const { faqs } = useContent();

  useEffect(() => {
    if (!faqs.length) return;
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [faqs]);

  return (
    <div className="flex flex-col gap-4 w-full">
      {faqs.map((faq, index) => {
        return (
          <div key={index} className="mb-8">
            <a href={`#${createSlug(faq.q)}`}>
              <h2
                id={createSlug(faq.q)}
                className="text-xl border-b-2 border-muted-foreground/50 font-bold p-2">
                {faq.q}
              </h2>
            </a>
            <HtmlEditor content={faq.a} readOnly />
          </div>
        );
      })}
    </div>
  );
};
