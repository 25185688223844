import {
  CollectionReference,
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  DocumentData,
} from 'firebase/firestore';
import { buyTicketFn } from './functions';
import { BuyTicketRequest, Collections, Ticket, TicketType } from '@packages/types';
import { firestore } from '@/firebase';

type FAQ = { q: string; a: string };

export class FirebaseClient {
  private ticketCollection: CollectionReference<Ticket, Ticket>;
  private ticketTypeCollection: CollectionReference<TicketType, TicketType>;
  private contentCollection: CollectionReference<DocumentData, DocumentData>;

  constructor() {
    this.ticketTypeCollection = collection(
      firestore,
      Collections.ticketTypes,
    ) as CollectionReference<TicketType, TicketType>;

    this.ticketCollection = collection(firestore, Collections.tickets) as CollectionReference<
      Ticket,
      Ticket
    >;
    this.contentCollection = collection(firestore, Collections.content);
  }

  async getTicketTypes(): Promise<TicketType[]> {
    const ticketTypeDocs = await getDocs(query(this.ticketTypeCollection));

    return ticketTypeDocs.docs.map((doc) => doc.data());
  }

  async getTicket(id: string) {
    try {
      const d = doc(this.ticketCollection, id);
      const result = await getDoc(d);
      return result.data() ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getHome() {
    try {
      const d = doc(this.contentCollection, 'home');
      const result = await getDoc(d);
      const data = result.data() as { de: string; en: string };
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getAwareness() {
    try {
      const d = doc(this.contentCollection, 'awareness');
      const result = await getDoc(d);
      const data = result.data() as { de: string; en: string };
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getProgram() {
    try {
      const d = doc(this.contentCollection, 'program');
      const result = await getDoc(d);
      const data = result.data() as { de: string; en: string };
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getFaqs() {
    try {
      const d = doc(this.contentCollection, 'faqs');
      const result = await getDoc(d);
      const data = result.data() as { de: FAQ[]; en: FAQ[] };
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async buyTicket(data: BuyTicketRequest) {
    return buyTicketFn({
      name: data.name,
      email: data.email,
      ticketType: data.ticketType,
      heardAboutUs: data.heardAboutUs,
      knowsFromOrga: data.knowsFromOrga,
      withParking: data.withParking,
    });
  }
}
