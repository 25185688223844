import { useLocation, useParams } from 'react-router-dom';
import { CheckIcon, ClipboardCopyIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { getTicketQuery } from '@/api/queries';
import { useTranslation } from 'react-i18next';

const paypalUsername = import.meta.env.VITE_PAYPAL_USERNAME;

export const TicketDetails = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { data: ticket } = useQuery(getTicketQuery(ticketId ?? ''));

  if (!ticket) return null;

  const ClipboardCopy = () => {
    const copy = () => {
      navigator.clipboard.writeText(ticket.ticketNumber);
      alert(t('ticketnumberCopied'));
    };
    return (
      <button type="button" className="ml-2" onClick={() => copy()}>
        <ClipboardCopyIcon className="w-4 h-4" />
      </button>
    );
  };

  if (ticket.canceledAt) {
    return (
      <>
        <div className="bg-background/40 p-4 border border-destructive rounded">
          <h2 className="mt-2 font-bold text-warning">{t('ticketCanceled')}</h2>
          <span className="text-destructive">{t('ticketCanceled_message')}</span>
          <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
            <dt>{t('ticketNumber')}:</dt>
            <dd>{ticket.ticketNumber}</dd>
            <dt>Email:</dt>
            <dd>{ticket.email}</dd>
          </dl>
        </div>
      </>
    );
  }

  return (
    <div>
      {search.includes('success') ? (
        <>
          <h1 className="font-bold text-lg">{t('success_thankYou')}</h1>
          <p className="text-foreground/90 mb-6">{t('success_subheader')}</p>
        </>
      ) : null}
      <h1 className="font-bold text-lg">{t('ticketStatus')}</h1>

      {!ticket.paidAt ? (
        <>
          <div className="bg-background/40 p-4 border border-warning rounded">
            <h2 className="mt-2 font-bold text-warning">{t('paymentPending')}</h2>
            <span className="text-destructive">{t('paymentPending_message')}</span>
            <p className="mb-4 mt-4 text-warning">
              {t('sendViaPaypal')}{' '}
              <a
                className="font-bold text-foreground"
                target="_blank"
                href={`https://paypal.me/${paypalUsername}/${ticket.price}`}
                rel="noreferrer">
                https://paypal.me/{paypalUsername}
              </a>
              <br />
              {t('message')}: <span className="text-foreground">{ticket.ticketNumber}</span>{' '}
              <ClipboardCopy />
            </p>
          </div>
        </>
      ) : null}

      <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
        {ticket.paidAt ? (
          <>
            <dt>Payment:</dt>
            <dd>
              <div className="flex text-success items-center">
                <CheckIcon className="mr-2" />
                {t('paymentReceivedOn')}{' '}
                {Intl.DateTimeFormat('de-DE').format(new Date(ticket.paidAt))}
              </div>
            </dd>
          </>
        ) : null}
        {ticket.ticketType === 'parking' && (
          <span className="text-warning border-warning border-2 px-4 py-2 rounded my-4 inline-block">
            {t('parkingOnly')}
          </span>
        )}
        <dt>{t('ticketNumber')}:</dt>
        <dd>
          {ticket.ticketNumber} <ClipboardCopy />
        </dd>
        <dt>Name:</dt>
        <dd>{ticket.name}</dd>
        <dt>Email:</dt>
        <dd>{ticket.email}</dd>
        <dt>{t('ticketPrice')}:</dt>
        <dd>{ticket.price} EUR</dd>
      </dl>
    </div>
  );
};
