import { FirebaseClient } from '@/api/firebaseClient';
import { QUERY_KEYS, ticketTypeQuery } from '@/api/queries';
import { useInvalidatingMutation } from '@/hooks/useInvalidatingMutation';
import { BuyTicketRequest, TicketType } from '@packages/types';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Input,
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Label,
  Switch,
} from '@packages/ui';
import { useTranslation } from 'react-i18next';

const PARKING_PRICE = 15;

export const TicketsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const client = new FirebaseClient();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [orga, setOrga] = useState('');
  const [parkingTicketNumber, setParkingTicketNumber] = useState('');
  const [heardOfUsFrom, setHeardOfUsFrom] = useState('');
  const [parking, setParking] = useState(false);
  const [error, setError] = useState('');
  const [ticketType, setTicketType] = useState('');

  const { data: ticketTypes, isLoading } = useQuery(ticketTypeQuery());
  const { mutateAsync: buyTicket } = useInvalidatingMutation([QUERY_KEYS.TICKETS], {
    mutationFn: async (data: BuyTicketRequest) => client.buyTicket(data),
  });

  const availableTicketTypes = useMemo(() => {
    return (ticketTypes?.filter((t) => t.available > 0) ?? []).sort((a, b) =>
      b.name === 'parking' ? -1 : a.price - b.price,
    );
  }, [ticketTypes]);

  const selectedTicketType = useMemo<TicketType>(() => {
    const type = availableTicketTypes.find((t) => t.name === ticketType);
    return type ?? availableTicketTypes[0];
  }, [availableTicketTypes, ticketType]);

  useEffect(() => {
    if (!ticketType && availableTicketTypes.length) {
      setTicketType(availableTicketTypes[0].name);
    }
  }, [availableTicketTypes, ticketType]);

  const onSubmit = async () => {
    setError('');
    const form: HTMLFormElement | null = document.getElementById(
      'ticketForm',
    ) as HTMLFormElement | null;
    if (!form) return;
    const isValid = form.reportValidity();
    if (!isValid) return;

    if (!email) {
      setError(t('pleaseEnterAnEmail'));
      return;
    }
    if (!name) {
      setError(t('pleaseEnterYourName'));
      return;
    }
    try {
      const result = await buyTicket({
        ticketType,
        name,
        email,
        withParking: ticketType === 'parking' ? false : parking,
        knowsFromOrga: ticketType === 'parking' ? parkingTicketNumber : orga,
        heardAboutUs: ticketType === 'parking' ? parkingTicketNumber : heardOfUsFrom,
      });
      navigate(`/tickets/${result.data.ticket.id}?success`);
    } catch (e) {
      console.error(t('errorBuyingTicket'), e);
      setError(t('ticketCouldNotBeBought'));
    }
  };

  if (isLoading) {
    return null;
  }

  if (!availableTicketTypes.length) {
    return <div>{t('noTicketsAvailable')}</div>;
  }

  const totalPrice = selectedTicketType.price + (parking ? PARKING_PRICE : 0);

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-xl font-medium pb-2">{t('tickets_title')}</h1>

      <form id="ticketForm" className="flex flex-col w-full gap-2 py-4 max-w-[400px] max-w-screen">
        <Select
          value={ticketType}
          onValueChange={(value: string) => {
            setTicketType(value);
          }}>
          <SelectTrigger className="w-full bg-white/10">
            <SelectValue placeholder={selectedTicketType.label} />
          </SelectTrigger>
          <SelectContent className="">
            {availableTicketTypes.map((type) => (
              <SelectItem key={type.name} value={type.name}>
                {type.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {selectedTicketType ? (
          <>
            <Label className="mt-2">Email</Label>
            <Input
              className="p-2 border rounded w-full"
              type="email"
              required
              placeholder="Email"
              value={email}
              onChange={(input) => setEmail(input.target.value)}
            />

            <Label className="mt-2">{t('full_name')}</Label>
            <Input
              className="p-2 border rounded w-full"
              name="name"
              required
              type="text"
              value={name}
              onChange={(input) => setName(input.target.value)}
              placeholder="First Last"
            />
            {selectedTicketType.name === 'parking' ? (
              <>
                <p className="mt-4 text-warning">{t('tickets_parking_ticket_selected')}</p>
                <Label className="mt-2">{t('tickets_parking_label')}</Label>
                <Input
                  className="p-2 border rounded w-full"
                  name="ticketNumber"
                  required
                  type="text"
                  value={parkingTicketNumber}
                  onChange={(input) => setParkingTicketNumber(input.target.value)}
                  placeholder="WW-ABCDE"
                />
              </>
            ) : (
              <>
                <Label className="mt-2">{t('tickets_orga_label')}</Label>
                <Input
                  className="p-2 border rounded w-full"
                  name="orga"
                  required
                  type="text"
                  value={orga}
                  onChange={(input) => setOrga(input.target.value)}
                  placeholder="Name"
                />
                <Label className="mt-2">{t('tickets_heard_label')}</Label>
                <Input
                  className="p-2 border rounded w-full"
                  name="orga"
                  required
                  type="text"
                  value={heardOfUsFrom}
                  onChange={(input) => setHeardOfUsFrom(input.target.value)}
                  placeholder={t('tickets_placeholder_heard')}
                />

                <Label className="mt-2">{t('addParkingTicket')}</Label>
                <div className="text-sm text-muted-foreground">{t('parking_note')}</div>
                <Switch checked={parking} onCheckedChange={(val) => setParking(val)} />
              </>
            )}

            <div className="cart mt-8 mb-4 flex flex-col text-lg">
              <span>{t('totalPrice')}</span>
              <span className="font-bold text-3xl">{totalPrice} EUR</span>
            </div>

            <Button onClick={() => onSubmit()} className="px-4 py-4 rounded text-white">
              {t('buy_ticket')}
            </Button>
          </>
        ) : null}

        {error && <div className="text-red-500">{error}</div>}
      </form>
    </div>
  );
};
