import { Link, NavLink, Outlet } from 'react-router-dom';
import background from '@/assets/bg.jpeg';
import video from '@/assets/background.mp4';
import logo from '@/assets/logo.png';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@packages/ui';
import './layout.css';

export const Layout = () => {
  const { t, i18n } = useTranslation();

  const toggleLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('de');
    } else {
      i18n.changeLanguage('en');
    }
  };

  const languageEmoji = i18n.language === 'en' ? '🇩🇪' : '🇬🇧';
  return (
    <div
      className="h-screen w-screen overflow-hidden relative"
      style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
      <video
        autoPlay
        muted
        playsInline
        loop
        className="absolute w-full h-screen object-cover pointer-events-none">
        <source src={video} type="video/mp4" />
      </video>
      <div className="h-screen w-screen bg-background absolute opacity-80"></div>
      <div className="w-screen h-screen z-10 absolute overflow-scroll">
        <div className="h-full flex flex-col">
          <div className="p-4 grow flex justify-center">
            <main className="text-white relative border rounded-xl p-8 border-white backdrop-blur-md pb-4">
              <div className="absolute top-5 right-5">
                <Tooltip content={t('changeLanguage')}>
                  <button onClick={toggleLanguage} className="text-xl leading-[12px]">
                    {languageEmoji}
                  </button>
                </Tooltip>
              </div>
              <nav className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 justify-center items-center mb-16">
                <div className="flex justify-end">
                  <Link to="/" className="">
                    <img src={logo} className="w-[300px] p-6 md:p-12" />
                  </Link>
                </div>

                <div className="flex pr-8">
                  <div className="grid grid-cols-1 grow-0 gap-4">
                    <NavLink to="/tickets" className="text-[2em] font-bold menu-link">
                      {t('tickets')}
                    </NavLink>
                    <NavLink to="/info" className="text-[2em] font-bold menu-link">
                      {t('info')}
                    </NavLink>
                    <NavLink to="/awareness" className="text-[2em] font-bold menu-link">
                      {t('awareness')}
                    </NavLink>
                    <NavLink to="/program" className="text-[2em] font-bold menu-link">
                      {t('program')}
                    </NavLink>
                  </div>
                </div>
              </nav>

              <div className="max-w-[800px]">
                <Outlet />
              </div>
            </main>
          </div>
          <footer className="border-t py-2 backdrop-blur bg-white/20 text-green-100 font-bold">
            <div className="container space-x-8 text-center flex justify-center items-center text-xs">
              <Link to="/imprint">{t('imprint')}</Link>
              <Link to="/privacy">{t('privacy')}</Link>
              <Tooltip content={t('changeLanguage')}>
                <button onClick={toggleLanguage} className="text-lg leading-[12px]">
                  {languageEmoji}
                </button>
              </Tooltip>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};
