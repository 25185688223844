// src/Tiptap.jsx
import './editor.scss';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import { Editor, EditorContent, useEditor, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useCallback, useEffect } from 'react';
import { cn } from '@packages/utils';
import BubbleMenuExtension from '@tiptap/extension-bubble-menu';
import LinkExtension from '@tiptap/extension-link';

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null;
  }

  const setImage = useCallback(() => {
    const url = window.prompt('Enter image URL');
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  if (!editor) return null;

  return (
    <div className="menu-bar">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}>
        bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}>
        italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}>
        strike
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={cn(editor.isActive('heading', { level: 1 }) ? 'is-active' : '', 'ml-2')}>
        h1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}>
        h2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
        h3
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}>
        h4
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={cn(editor.isActive({ textAlign: 'left' }) ? 'is-active' : '', 'ml-2')}>
        left
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}>
        center
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}>
        right
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={cn(editor.isActive('bulletList') ? 'is-active' : '', 'ml-2')}>
        bullet list
      </button>
      <button onClick={setImage}>add image</button>

      <button
        onClick={() => editor.chain().focus().undo().run()}
        className="ml-2"
        disabled={!editor.can().chain().focus().undo().run()}>
        undo
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}>
        redo
      </button>
    </div>
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({}),
  LinkExtension.configure({
    autolink: true,
    linkOnPaste: true,
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph', 'image'],
  }),
  Image.configure({ inline: true }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  BubbleMenuExtension.configure({
    element: document.querySelector('.menu-bar') as HTMLElement | null,
  }),
];

type Props = {
  content: string;
  setContent?: (content: string) => void;
  hideMenu?: boolean;
  readOnly?: boolean;
};
export const HtmlEditor = (props: Props) => {
  const editor = useEditor({
    extensions,
    editable: !props.readOnly,
    content: JSON.parse(props.content),
    onUpdate: ({ editor }) => {
      if (!props.setContent) return;
      props.setContent(JSON.stringify(editor.getJSON()));
    },
  });

  useEffect(() => {
    if (props.content !== JSON.stringify(editor?.getJSON())) {
      editor?.commands.setContent(JSON.parse(props.content));
    }
  }, [props.content]);

  return (
    <>
      {!props.hideMenu && !props.readOnly && editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100, maxWidth: '80vw' }}>
          <MenuBar editor={editor} />
        </BubbleMenu>
      )}
      <EditorContent editor={editor} />
    </>
  );
};
